const news = {
	msnry: null,

	init() {
		let feed = document.querySelector('.news__feed');
		if (feed !== null) {
			this.msnry = new Masonry(feed, {
				columnWidth: '.card-news',
				gutter: 16,
				horizontalOrder: true,
				itemSelector: '.card-news',
				percentPosition: true
			});
			this.msnry.layout();
		}
	}

};

export { news };
