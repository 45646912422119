import { Tabs } from 'foundation-sites';

const tabs = {
	elem: {
		$body: $('body'),
		$tabs: $('[data-tabs]'),
		$tab: $('[data-tabs] a'),
		$refresh: false,
	},

	tab: null,

	init() {
		if(this.elem.$tabs.length) {
			this.tab = new Tabs(this.elem.$tabs, {});
			this.elem.$tabs.on('change.zf.tabs', function (e) {
				let pRefresh = e.target.dataset.refresh === 'true';

				if (pRefresh) {
					//console.log(e);
					$('#degrees_carousel, #certificates_carousel').slick('refresh');
					e.target.setAttribute('data-refresh', 'true');
				}
			});
		}
	}
};

export { tabs };

// to use:
//
// import tabs from './modules/tabs';
//
// tabs.init();
